/* eslint-disable no-magic-numbers */
import { ComponentRef, InjectionToken, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UtilDatePickerComponent } from './util-date-picker/util-date-picker.component';

// eslint-disable-next-line
export const BASE_DATE_PICKER_OPTIONS_INJECTION_TOKEN = new InjectionToken<UtilDatePickerOptions>('BASE_DATE_PICKER_OPTIONS_INJECTION_TOKEN');

export const BASE_DATE_PICKER_COMPONENT_INJECTION_TOKEN = new InjectionToken<typeof UtilDatePickerComponent>('BASE_DATE_PICKER_OPTIONS_INJECTION_TOKEN');


export class UtilDatePickerRef {

  private resultSubject = new Subject<UtilDatePickerPageCell>();
  private componentBehaviorSubject = new BehaviorSubject<ComponentRef<any>>(null!);

  setComponentRef(cmpRef: ComponentRef<any>) {
    this.componentBehaviorSubject.next(cmpRef);
  }

  close(result?: UtilDatePickerPageCell) {
    this.componentBehaviorSubject.value.destroy();
    this.resultSubject.next(result!);
    this.resultSubject.complete();
  }

  /**
   * waits until the user submits a result - does not include the time of a possible delay like an animation
   */
  waitForResult(): Observable<UtilDatePickerPageCell> {
    return this.resultSubject.asObservable();
  }
}


export interface UtilDatePickerOptions {

  datePickerRef?: UtilDatePickerRef;
  /**
   * an injector, which will be used as a parent injector for the modal.
   * Useful if the component (from which the modal is opened) has an injector that provides different data.
   * - An injector that differs from the injector of the root level
   */
  injector?: Injector;
  /**
   * describes the initial options, used by the date picker component at the start
   */
  startingPageOptions?: GetCalendarPageOptions;
  /**
   * true by default
   */
  closeableWithBackdraftClick?: boolean;
  /**
   * true by default
   */
  closableByEscape?: boolean;
  /**
   * attach to this element - if falsey it will be displayed in the middle
   */
  anchorEl?: HTMLElement;
  /**
   *
   */
  openedBy?: 'pointer' | 'keyboard';
  /**
   *
   */
  separateYearSelection?: boolean;
  /**
   *
   */
  translations?: {
    deleteAction?: string;
    todayAction?: string;
    closeAction?: string;
  };
}


export interface UtilDatePickerPageCell {
  localized: string;
  raw: string;
  iso: string;
  instance?: Date;
  /**
   * relative day number of target month
   */
  number?: number;
  /**
   * cell number of the current page
   */
  cellNumber: number;
  weekdayLong?: string;
  weekdayShort?: string;
  weekday?: (1 | 2 | 3 | 4 | 5 | 6 | 7);
  monthLong?: string;
  monthShort?: string;
  isWeekend?: boolean;
  isCurrent?: boolean;
  dayOfCurrentMonth?: boolean;
  selectable?: boolean;
  selected?: boolean;
  action?: 'selected' | 'today' | 'delete';
}

export interface UtilDatePickerPage {
  locale: string;
  month: number;
  monthLabel: string;
  fullYear: number;
  weekDayCellLabels: string[];
  cells: UtilDatePickerPageCell[];
}

export interface GetCalendarPageOptions {
  locale?: string;
  min?: Date;
  max?: Date;
  selectableWeekdays?: (1 | 2 | 3 | 4 | 5 | 6 | 7)[];
  current?: Date;
  beforeCreateDate?: (beforeDate: Date) => Date;
}
